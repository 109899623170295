import { useMediaQuery } from "@mui/material";
import React from "react";
import { SocialIcon } from "react-social-icons";
import "../styles/App.css";
import "../styles/Menu.css";
import CenterWrapper from "./CenterWrapper";
import Section from "./Section";
import TopPadding from "./TopPadding";
import VideoGrid from "./VideoGrid";
import SpotifyCard from "./SpotifyCard";

function App() {
  const isBigScreen = useMediaQuery("(min-width:660px)");

  const getImage = () => {
    if (isBigScreen) {
      return "title.jpg";
    } else {
      return "title_small.jpg";
    }
  };
  // imp
  return (
    <div>
      <div style={{ position: "fixed", top: 30, right: 30 }}>
        Folg mir{" "}
        <SocialIcon
          target="_blank"
          url="https://www.instagram.com/riedmann.rocks/"
          bgColor="white"
        />
      </div>
      <Section id="team" url={getImage()}>
        <TopPadding padding="90">
          <div className="p-8 relative h-screen">
            <div className="text-8xl absolute bottom-28">
              <div className="text-6xl md:text-8xl">riedmann</div>
              <div style={{ display: "flex" }}>
                <a
                  href="https://open.spotify.com/artist/32LsUa4icBbUvKaUMkjzSe"
                  rel="noreferrer"
                  target="_blank"
                  style={{ position: "relative", top: "-20px" }}
                >
                  <i
                    style={{
                      fontSize: "40px",
                      marginRight: "10px",
                    }}
                    className="fa fa-spotify fa-1x"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  href="https://music.apple.com/ch/artist/kurbad-rossi/1612620925"
                  rel="noreferrer"
                  target="_blank"
                  style={{ position: "relative", top: "-20px" }}
                >
                  <i
                    style={{
                      fontSize: "40px",
                      position: "relative",
                      top: "-5px",
                      marginRight: "10px",
                    }}
                    className="fa fa-apple fa-1x"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  href="https://music.amazon.com/artists/B0BMT9YJKZ/riedmann"
                  rel="noreferrer"
                  target="_blank"
                  style={{ position: "relative", top: "-17px" }}
                >
                  <i
                    style={{
                      fontSize: "40px",
                      position: "relative",
                      top: "-5px",
                    }}
                    className="fa fa-amazon fa-1x"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </TopPadding>
      </Section>
      <Section id="songs" url="title2.jpg">
        <div className="w-full  p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <SpotifyCard url="https://open.spotify.com/embed/track/5zw02mjkUtbxbsC4I2FkeV?utm_source=generator" />
            <SpotifyCard url="https://open.spotify.com/embed/track/65VQqV1GTtLAjE7QZWKsxq?utm_source=generator" />
            <SpotifyCard
              tstyle=" sm:block"
              url="https://open.spotify.com/embed/track/1uqGYrM2V0hIB9W67x5UjS?utm_source=generator"
            />
            <SpotifyCard
              tstyle="   sm:block"
              url="https://open.spotify.com/embed/track/0Xbn1WAZB0i4TGsIVwdE8T?utm_source=generator"
            />
          </div>
        </div>
      </Section>
      <Section id="team" url="title2.jpg">
        <div
          style={{ display: "flex", alignItems: "center", minHeight: "100vh" }}
        >
          <div
            style={{
              paddingLeft: "10vw",
              width: "80vw",
              letterSpacing: "0.2em",
              lineHeight: "1.2em",
              fontSize: "1.5em",
              fontWeight: "200",
            }}
          >
            Songs are stories. Geschichten des Lebens. Befreit von jeder
            Konvention. Englisch. Deutsch. Einfach Songs. Simply. Entstanden in
            den Bergen, am Meer oder im Wohnzimmer. Zwischen Kindern und
            Wegbegleitern. Mitten im Leben. Gravity.
          </div>
        </div>
      </Section>
      <Section id="team" url="title.jpg">
        <div style={{ padding: "20px" }}>
          <VideoGrid collection="videos" />
        </div>
      </Section>

      <Section id="team" url="title.jpg">
        <CenterWrapper>andreas@riedmann.rocks</CenterWrapper>
      </Section>

      <div className="p-8 bg-[#686518]">
        <a href="/impressum.pdf">Impressum</a>
        <div>
          created by
          <a
            className="ml-2"
            target="_blank"
            rel="noopener noreferrer"
            href="https://riedmann.rocks"
          >
            riedmann.rocks
          </a>
        </div>
        <div>©riedmann.rocks</div>
      </div>
    </div>
  );
}

export default App;
