import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

export default function VideoGrid({ collection }) {
  const [videos, setVideos] = useState([]);
 
  let url = "https://api.riedmann.rocks/rr/items/" + collection;

  useEffect(() => {
    fetch(url).then((response) =>
      response.json().then((json) => {
        if (!json.error) {
          setVideos(json.data);
        }
      })
    );
  }, [url]);

  const getVideoGrid = () => {
    let widgets = [];
    let key = 0;
    videos.forEach((element) => {
      const url = "https://www.youtube.com/embed/" + element.videoid;

      widgets.push(
        <Grid item xs={12} sm={6} md={4} key={key}>
          <iframe
            style={{ border: "none" }}
            width="100%"
            height="415"
            src={url}
            title="riedmann"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen"
          ></iframe>
        </Grid>
      );
      key++;
    });

    return widgets;
  };

  return (
    <Grid container spacing={2}>
      {getVideoGrid()}
    </Grid>
  );
}
